<template>
  <div class="position-relative d-block user-select-none">
    <b-form-checkbox v-model="checked" switch>
      <label>{{ label }}</label>
    </b-form-checkbox>
  </div>
</template>

<script>
import inputMixin from './inputMixin'

export default {
  mixins: [
    inputMixin
  ],
  data () {
    return {
      checked: false
    }
  },
  created () {
    this.checked = this.value
  },
  watch: {
    checked (val) {
      this.$emit('value-change', val)
    }
  }
}
</script>
